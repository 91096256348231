import {
    resetKnowledgeBase,
    setActiveFlags,
    setActiveTab,
} from "store/knowledgeBasePageSlice";
import {
    setCallerId,
    setFormSubmitted,
    setGeographies,
    setIsCarrierOPEKA,
    setSelectedGeography,
} from "store/ticketCreateSlice";
import {useState} from "react";
import {Navigate, useBlocker, useLocation, useNavigate, useParams} from "react-router-dom";
import fetchTicketById from "app/Api Calls/FetchTicketById";
import TicketEditAccordion from "./TicketEditAccordion";
import {
    Box,
    Button,
    ToggleButton,
    ToggleButtonGroup,
} from "@mui/material";
import {useEffect} from "react";
import {useRef} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {useDispatch, useSelector} from "react-redux";
import {
    resetOriginalBeneficiaryData,
    resetOriginalRepresentativeData,
    resetUserForm,
    setBeneficiaryId, setRepresentativeId,
} from "store/userInfoSlice";
import {setMandatoryFields, setSelectedResolution} from "store/resolutionsSlice";
import {
    resetEditOriginalRepresentative,
    resetEditTicketFlags, setComment, setEditMode, setEditOriginalBeneficiary,
    setFetchedGeographyId,
    setHasThematicsChanged, setHasUnsavedChanges,
    setIsTicketFetched, setIsUserInfoFetched,
    setOriginalAsignee, setPreviousTicketVersion,
    setSelectedTeamForDirectAssignment,
    setSelectedUserForDirectAssignment,
    setTeam,
    setTicket
} from "store/editTicketSlice";
import TicketFilesComponent from "./Files/WebFiles";
import {clearBeneficiaryLastSearchParams, clearFetchedBeneficiaries} from "store/beneficiaryFetchSlice";
import SubmitWithResolution from "app/main/Reusable Components/Ticket Reusable/SubmitWithResolution";
import IstorikoTicket from "app/main/tickets/create-ticket/IstorikoTicket";
import DirectAssignment from "./DirectAssignment";
import GroupAssignment from "./GroupAssignment";
import BeneficiaryRepresentativeAccordion from "./BeneficiaryRepresentativeAccordion";
import CarrierAndThematicAccordion from "./CarrierAndThematicAccordion";
import MandatoryFieldsList from "app/main/tickets/create-ticket/MandatoryFieldsList";
import {jwtService} from "app/auth/jwtService";
import settingsConfig from "app/configs/settingConfig";
import {checkForTicketChanges} from "app/main/Reusable Components/TicketChanges";
import BlockNavigationDialog from "./BlockNavigationDialog";
import {hasBeneficiaryDataChanged} from "app/main/Reusable Components/IsBeneficiaryDataChanged";
import {clearTicketsData, setFollowupTicketId} from "store/historyTicketSlice";
import ChangeDetectedDialog from "./ChangeDetectedDialog";
import LinearProgress from '@mui/material/LinearProgress';
import {hasRepresentativeChanged} from "app/main/Reusable Components/HasRepresentaticeChanged";
import {clearFetchedRepresentatives, clearRepresentativeLastSearchParams} from "store/representaticeFetchSlice";

const customStyles = {
    selected: {
        backgroundColor: "#003476",
        color: "#FFFFFF",
        border: "1px solid #003476",
    },
};

function EditPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = useParams();
    const [activeStatus, setActiveStatus] = useState(null);
    const [expandedPanels, setExpandedPanels] = useState(["Τicket", "Θεματικές", "Δικαιούχος και Αντιπρόσωπος"]);
    const [directAssignmentUpdate, setDirectAssignmentUpdate] = useState(false);
    const [saAssignmentUpdate, setSaAssignmentUpdate] = useState(false);
    const [teamDirectAssignmentUpdate, setTeamDirectAssignmentUpdate] =
        useState(false);
    const activeTab = useSelector(
        (state) => state.knowledgeBasePageSlice.activeTab
    );
    const ticket = useSelector((state) => state.editTicketSlice.ticket);
    const isTicketFetched = useSelector(
        (state) => state.editTicketSlice.isTicketFetched
    );
    const [mainSelection, setMainSelection] = useState("");
    const [ticketVersion, setTicketVersion] = useState(0);
    const selectedSubSubthematic = useSelector((state) => state.knowledgeBasePageSlice.selectedSubSubthematic);
    const activeFlags = useSelector((state) => state.knowledgeBasePageSlice.activeFlags);
    const selectedGeography = useSelector((state) => state.ticketSlice.selectedGeography);
    const [hasEditAccess, setHasEditAccess] = useState(false);
    const callerId = useSelector((state) => state.ticketSlice.callerId);
    const originalBeneficiaryData = useSelector(
        (state) => state.userInfoSlice.originalBeneficiaryData
    );
    const currentBeneficiary = useSelector((state) => state.userInfoSlice.currentBeneficiary)
    const [showUnsavedChangedDialog, setShowUnsavedChangedDialog] = useState(false);
    const isLoadingThematics = useSelector((state) => state.knowledgeBasePageSlice.isLoading)
    const panelRefs = {
        Τicket: useRef(null),
        "Δικαιούχος και Αντιπρόσωπος": useRef(null),
        Θεματικές: useRef(null),
        Σχόλια: useRef(null),
    };
    const userData = jwtService.getUser();
    const selectedSubThematic = useSelector((state) => state.knowledgeBasePageSlice.selectedSubthematic)
    const hasUnsavedChanges = useSelector((state) => state.editTicketSlice.hasUnsavedChanges);
    const subSubthematics = useSelector(
        (state) => state.knowledgeBasePageSlice.subSubthematics
    );
    const userFilled = useSelector((state) => state.userInfoSlice.userFilled);
    const representativeFilled = useSelector((state) => state.userInfoSlice.representativeFilled);
    const isAgent = settingsConfig.roles.agent.includes(userData?.roleName);
    const representativeHasChanged = useSelector((state) => state.editTicketSlice.representativeChanges)
    const editOriginalRepresentative = useSelector(
        (state) => state.editTicketSlice.editOriginalRepresentative
    );
    const currentRepresentative = useSelector((state) => state.userInfoSlice.currentRepresentative)
    const isSupervisor = settingsConfig.roles.supervisor.includes(userData?.roleName);
    const isLoadingBeneficiary = useSelector((state) => state.beneficiaryFetchSlice.isLoadingBeneficiary);
    const isLoadingSubSubThematics = useSelector(
        (state) => state.knowledgeBasePageSlice.isLoadingSubSubThematics
    );
    const representativeId = useSelector(state => state.userInfoSlice.representativeId);

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(setEditMode(true));
        dispatch(setActiveTab(0));
        dispatch(resetUserForm());
        dispatch(resetKnowledgeBase());
        if (!ticket && !isTicketFetched || directAssignmentUpdate || teamDirectAssignmentUpdate || saAssignmentUpdate) {
            fetchTicketData();
        }
        return () => {
            dispatch(setEditMode(false));
            dispatch(setMandatoryFields([]));
            dispatch(resetUserForm());
            dispatch(resetKnowledgeBase());
            dispatch(resetOriginalBeneficiaryData());
            dispatch(resetOriginalRepresentativeData());
            dispatch(setBeneficiaryId(null));
            dispatch(setRepresentativeId(null));
            dispatch(setActiveFlags([]));
            dispatch(setSelectedResolution(null));
            dispatch(clearTicketsData());
            dispatch(resetEditTicketFlags());
            dispatch(setIsCarrierOPEKA(false));
            dispatch(clearBeneficiaryLastSearchParams());
            dispatch(clearFetchedBeneficiaries());
            dispatch(clearRepresentativeLastSearchParams());
            dispatch(clearFetchedRepresentatives());
            dispatch(setTeam(null));
            dispatch(setOriginalAsignee(null));
            dispatch(setTicket(null));
            dispatch(setIsTicketFetched(false));
            dispatch(setHasThematicsChanged(false));
            dispatch(setHasUnsavedChanges(false));
            dispatch(setEditOriginalBeneficiary([]));
            dispatch(setPreviousTicketVersion(0));
            dispatch(setActiveTab(0));
            dispatch(setComment(""));
            dispatch(setGeographies([]));
            dispatch(setFetchedGeographyId(null));
            dispatch(setSelectedGeography(null));
            dispatch(resetEditOriginalRepresentative());
            dispatch(setSelectedUserForDirectAssignment(null));
            dispatch(setSelectedTeamForDirectAssignment(null));
            dispatch(setFollowupTicketId(null));
        };
    }, [id, saAssignmentUpdate]);

    //Callback function for reseting the ticket when direct assignment
    const handleDirectAssignmentUpdate = (updated) => {
        setDirectAssignmentUpdate(updated);
        dispatch(setSelectedUserForDirectAssignment(null));
        fetchTicketData();
    };
    //Callback function for reseting the ticket when group assignment

    const handleTeamDirectAssignmentUpdate = (updated) => {
        setTeamDirectAssignmentUpdate(updated);
        dispatch(setSelectedTeamForDirectAssignment(null));
        fetchTicketData();
    };
    //Callback function for reseting the ticket when we update the ticket

    const handleUpdateTicket = (updated) => {
        fetchTicketData();
        dispatch(setHasThematicsChanged(false));
        dispatch(setHasUnsavedChanges(false))
        setShowUnsavedChangedDialog(false);
    }

    const fetchTicketData = async () => {
        try {
            dispatch(setIsTicketFetched(false));
            const fetchedTicket = await fetchTicketById(id);
            if (fetchedTicket) {
                dispatch(setTicket(fetchedTicket));
                checkEditAccess(fetchedTicket);
            }
            setTicketVersion((prev) => prev + 1);
            setActiveStatus(fetchedTicket?.ticketStatusId);
            dispatch(setBeneficiaryId(fetchedTicket?.beneficiaryId));
            if (fetchedTicket && fetchedTicket?.ticketFlagCollection) {
                dispatch(setActiveFlags(fetchedTicket?.ticketFlagCollection));
            }
            if (fetchedTicket && fetchedTicket?.callerId) {
                dispatch(setCallerId({value: fetchedTicket?.callerId, isValid: true}));
            }
            if (fetchedTicket && fetchedTicket?.geographyId) {
                dispatch(setFetchedGeographyId(fetchedTicket?.geographyId));
            }
            if (fetchedTicket && fetchedTicket?.assigneeUserId) {
                dispatch(setOriginalAsignee(fetchedTicket?.assigneeUserId));
            }
            dispatch(setIsTicketFetched(true));
            dispatch(setIsUserInfoFetched(false));
            // Reset the flags to false
            setDirectAssignmentUpdate(false);
            setTeamDirectAssignmentUpdate(false);
        } catch (err) {
            console.error(err);
        }
    };

    const handleTabSelect = (index) => {
        dispatch(setActiveTab(index));
    };

    const onFormSubmit = () => {
        dispatch(setFormSubmitted(true));
    };

    const shouldShowToggleButtonGroup = () => {
        return !isAgent && activeStatus !== 5;
    };

    const handlePanelToggle = (panel) => {
        if (expandedPanels.includes(panel)) {
            setExpandedPanels((prevPanels) =>
                prevPanels.filter((item) => item !== panel)
            );
        } else {
            setExpandedPanels((prevPanels) => [...prevPanels, panel]);
        }

        if (panelRefs[panel].current) {
            panelRefs[panel].current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    };

    const handleBackToTicketList = () => {
        navigate("/tickets");
    };


    const checkEditAccess = (ticketData) => {
        const userData = jwtService.getUser();
        const userRole = userData?.roleName;
        const isUserAdmin = userRole === settingsConfig.roles.admin;
        const isUserSupervisorOrTeamLeader = [
            ...settingsConfig.roles.supervisor,
            ...settingsConfig.roles.teamLeader,
        ].includes(userRole);

        const isAssigneeUser = Number(ticketData.assigneeUserId) === Number(userData.id);

        const hasAccess = isUserAdmin || isUserSupervisorOrTeamLeader || isAssigneeUser;

        if (!hasAccess) {
            navigate("/tickets", {replace: true});
        } else {
            setHasEditAccess(true);
        }
    };
    // Navigation block when ticket or beneficiary changes
    let blocker = useBlocker(({nextLocation}) => {
        if (location.pathname !== nextLocation.pathname && isTicketFetched && ticket?.ticketStatusId === 4) {
            // Evaluate ticket and beneficiary changes
            const {thematicChanged, flagsChanged, geographyChanged} = checkForTicketChanges(
                ticket,
                selectedSubSubthematic,
                selectedSubThematic,
                activeFlags,
                selectedGeography,
                subSubthematics
            );
            const beneficiaryHasChanged = hasBeneficiaryDataChanged(originalBeneficiaryData, currentBeneficiary);
            const representativeChanges = hasRepresentativeChanged(editOriginalRepresentative, currentRepresentative)


            // Block navigation if changes are detected
            if (thematicChanged || flagsChanged || geographyChanged || beneficiaryHasChanged || representativeChanges) {
                return true; // Block navigation
            }
        }

        // Allow navigation if no changes are detected or navigating within the same page
        return false;
    }, [ticket]);

    const handleActionClick = () => {
        setShowUnsavedChangedDialog(true);
    }
    const handleCloseDialog = () => {
        setShowUnsavedChangedDialog(false);
    };

    return (
        <div className="main-container-KnowledgeBase w-full">
            <div className="flex flex-col justify-start items-start w-full">
                {/* Flex container to place tabs and conditional content side by side */}
                <div className="flex justify-between items-start w-full">
                    <div>
                        <Tabs value={activeTab} onChange={(event, newValue) => handleTabSelect(newValue)}>
                            <Tab label="Πληροφορίες"/>
                            <Tab label="CRM"/>
                        </Tabs>
                        <Button onClick={handleBackToTicketList}
                                className="bg-[#003476] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 mb-1">
                            Πίσω στην Λίστα
                        </Button>
                    </div>
                    {/* Conditional content to be displayed alongside tabs */}
                    {ticket && isTicketFetched && activeTab === 0 && (
                        <div className="flex justify-end w-full">
                            {isLoadingThematics || isLoadingBeneficiary || isLoadingSubSubThematics ? (
                                    <div style={{width: '20%', display: 'flex', justifyContent: 'flex-end'}}>
                                        <LinearProgress style={{width: '100%'}}/>
                                    </div>
                                ) :
                                (((!selectedSubThematic && subSubthematics.length === 0) || (!selectedSubSubthematic && subSubthematics.length !== 0) || (!userFilled && ticket?.beneficiaryId) || (!representativeFilled && representativeHasChanged)) && activeStatus !== 5) ? (
                                    (<div className="max-w-md w-full bg-white shadow-md rounded-lg p-4">
                                            <ul className="list-disc list-inside space-y-2">
                                                {((!selectedSubThematic && subSubthematics.length === 0) || (!selectedSubSubthematic && subSubthematics.length !== 0)) && (
                                                    <li className="text-red-500">Πρέπει να επιλέξετε όλο το δέντρο
                                                        της θεματολογίας.</li>
                                                )}
                                                {!userFilled && (
                                                    <li className="text-red-500">Πρέπει να συμπληρώσετε όλα τα
                                                        απαραίτητα στοιχεία του δικαιούχου (Όνομα, Επώνυμο, ΑΦΜ,
                                                        Τηλέφωνο).</li>
                                                )}
                                                {!representativeFilled && representativeHasChanged && (
                                                    <li className="text-red-500">Πρέπει να συμπληρώσετε όλα τα
                                                        απαραίτητα στοιχεία του αντιπροσώπου (Όνομα, Επώνυμο, ΑΦΜ,
                                                        Τηλέφωνο).</li>
                                                )}
                                            </ul>
                                        </div>
                                    )
                                ) : (hasUnsavedChanges && activeStatus !== 5) ? (
                                    <div className="w-[300px] ml-2">
                                        <Button
                                            onClick={handleActionClick}
                                            className="bg-[#003476] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                                        >
                                            Αποθήκευση αλλαγών
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                dispatch(setHasUnsavedChanges(false));
                                                dispatch(setBeneficiaryId(null));
                                                fetchTicketData();
                                            }}
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mt-4"
                                        >
                                            Διαγραφή αλλαγών
                                        </Button>
                                    </div>
                                ) : (
                                    // Show ToggleButtonGroup and SubmitWithResolution when there are no errors or unsaved changes
                                    <div className="flex justify-end w-full">
                                        <div className="mr-4">
                                            <MandatoryFieldsList/>
                                        </div>
                                        {shouldShowToggleButtonGroup() && (
                                            <ToggleButtonGroup
                                                exclusive
                                                value={mainSelection}
                                                onChange={(e, newVal) => setMainSelection(newVal)}
                                            >
                                                <ToggleButton value="individual"
                                                              style={mainSelection === "individual" ? {
                                                                  ...customStyles.selected,
                                                                  fontSize: "12px"
                                                              } : {
                                                                  backgroundColor: "white",
                                                                  border: "1px solid #003476",
                                                                  fontSize: "12px"
                                                              }}>
                                                    Άτομο
                                                </ToggleButton>
                                                <ToggleButton value="group" style={mainSelection === "group" ? {
                                                    ...customStyles.selected,
                                                    fontSize: "12px"
                                                } : {
                                                    backgroundColor: "white",
                                                    border: "1px solid #003476",
                                                    fontSize: "12px"
                                                }}>
                                                    Ομάδα
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        )}
                                        {mainSelection === "individual" && (
                                            <DirectAssignment
                                                ticket={ticket}
                                                ticketId={ticket.id}
                                                onUpdate={handleDirectAssignmentUpdate}
                                            />
                                        )}
                                        {mainSelection === "group" && (
                                            <GroupAssignment
                                                thematicId={ticket.thematicId}
                                                ticket={ticket}
                                                onUpdate={handleTeamDirectAssignmentUpdate}
                                            />
                                        )}
                                        <div className="w-[300px] ml-2">
                                            {(activeStatus !== 5 || isSupervisor) && (
                                                <div className="w-[300px] ml-2">
                                                    <SubmitWithResolution
                                                        onSubmit={onFormSubmit}
                                                        editMode={true}
                                                        ticketId={ticket.id}
                                                        ticketBeneficiary={ticket.beneficiaryId}
                                                        ticketRepresentative={ticket.representativeId}
                                                        onUpdate={setSaAssignmentUpdate}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    )}
                </div>
                {activeTab === 0 && (
                    <Box className="w-full">
                        <form className="mt-2 rounded-lg w-full">
                            <div className="scroll-container">
                                <TicketEditAccordion
                                    id={id}
                                    ticket={ticket}
                                    expandedPanels={expandedPanels}
                                    onPanelToggle={handlePanelToggle}
                                    panelRefs={panelRefs}
                                    callerId={callerId}
                                />
                                <TicketFilesComponent ticketId={ticket?.id} ticketUUID={ticket?.uuid}/>
                                <BeneficiaryRepresentativeAccordion
                                    isEditMode={true}
                                    ticket={ticket}
                                    expandedPanels={expandedPanels}
                                    onPanelToggle={handlePanelToggle}
                                    panelRefs={panelRefs}
                                />
                                <CarrierAndThematicAccordion
                                    ticket={ticket}
                                    expandedPanels={expandedPanels}
                                    onPanelToggle={handlePanelToggle}
                                    panelRefs={panelRefs}
                                    directAssignmentUpdate={directAssignmentUpdate}
                                    ticketVersion={ticketVersion}
                                    onUpdate={handleUpdateTicket}
                                />
                            </div>
                        </form>
                    </Box>
                )}
                {activeTab === 1 && (
                    <Box className="w-full">
                        <IstorikoTicket isEditPage={true}/>
                    </Box>
                )}
            </div>

            {blocker.state === "blocked" ? (
                <BlockNavigationDialog
                    open={true}
                    blocker={blocker}
                />
            ) : null}
            <ChangeDetectedDialog ticketId={ticket?.id} onUpdate={handleUpdateTicket}
                                  showUnsavedChangedDialog={showUnsavedChangedDialog}
                                  onCloseDialog={handleCloseDialog}/>
        </div>
    )
}

export default EditPage;
