import {useState} from "react";
import {
    Box,
    AccordionDetails,
    Accordion,
    AccordionSummary, Tooltip, Chip, TextField,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tooltipClasses} from '@mui/material/Tooltip';
import {useDispatch, useSelector} from "react-redux";
import {styled} from '@mui/material/styles';


import {
    changeName,
    changeSurname,
    changeAfm,
    changeAmka,
    changePhone,
    changeMobile,
    changeEmail,
    changeAddress,
    changeRepresentativeAfm,
    changeRepresentativeFirstName,
    changeRepresentativeLastName,
    changeRepresentativeEmail,
    changeRepresentativePhone,
    changeRepresentativeMobile,
    resetUserForm,
    setBeneficiaryHasOpenTickets,
    setBeneficiaryId,
    setShouldCreateBeneficiary, setHasBeneficiaryFetched, changeProfession, changeSpecialInformation,
} from "store/userInfoSlice";
import CustomTextField from "app/main/Reusable Components/InputCustom";
import {
    validateAFM,
    validateAMKA,
    validateAddress,
    validateEmail,
    validateMobile,
    validateName,
    validatePhone,
    validateSurname, validateProfession, validateSpecialInformation,
} from "app/main/Fields/ValidateFields";
import FetchBeneficiary from "./FetchBeneficiaryWithModal"
import FetchRepresentative from "app/main/tickets/create-ticket/RepresentativeWithModal";
import OpenTicketsModal from "app/main/tickets/create-ticket/OpenTicketsModal";
import {setHistoryModal} from "store/ticketCreateSlice";
import {hasBeneficiaryDataChanged} from "app/main/Reusable Components/IsBeneficiaryDataChanged";
import {getBeneficiaryFieldChanges} from "app/main/Reusable Components/Ticket Reusable/getBeneficiaryFieldChanges";
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import {clearBeneficiaryLastSearchParams, clearFetchedBeneficiaries} from "store/beneficiaryFetchSlice";
import {setFollowupTicketId} from "store/historyTicketSlice";
import {setActiveFlags} from "store/knowledgeBasePageSlice";

//we pass accordion ref to know that this is rendered from the edit Page so we can avoid the initial fetching of beneficiary with his values
function UserInfo({isEditMode}) {
    const firstname = useSelector((state) => state.userInfoSlice.firstname);
    const surname = useSelector((state) => state.userInfoSlice.surname);
    const afm = useSelector((state) => state.userInfoSlice.afm);
    const amka = useSelector((state) => state.userInfoSlice.amka);
    const phone = useSelector((state) => state.userInfoSlice.phone);
    const mobile = useSelector((state) => state.userInfoSlice.mobile);
    const email = useSelector((state) => state.userInfoSlice.email);
    const address = useSelector((state) => state.userInfoSlice.address);
    const userFilled = useSelector((state) => state.userInfoSlice.userFilled);
    const beneficiaryHasOpenTickets = useSelector((state) => state.userInfoSlice.beneficiaryHasOpenTickets);
    const beneficiaryId = useSelector((state) => state.userInfoSlice.beneficiaryId);
    const carriers = useSelector((state) => state.knowledgeBasePageSlice.carriers);
    const representativeFilled = useSelector(
        (state) => state.userInfoSlice.representativeFilled
    );
    const profession = useSelector((state) => state.userInfoSlice.profession);
    const specialInformation = useSelector((state) => state.userInfoSlice.specialInformation);

    const representativeAfm = useSelector(
        (state) => state.userInfoSlice.representativeAfm
    );
    const representativeFirstName = useSelector(
        (state) => state.userInfoSlice.representativeFirstName
    );
    const activeFlags = useSelector((state) => state.knowledgeBasePageSlice.activeFlags)


    const representativeLastName = useSelector(
        (state) => state.userInfoSlice.representativeLastName
    );
    const representativeEmail = useSelector(
        (state) => state.userInfoSlice.representativeEmail
    );
    const representativePhone = useSelector(
        (state) => state.userInfoSlice.representativePhone
    );
    const originalBeneficiaryData = useSelector(
        (state) => state.userInfoSlice.originalBeneficiaryData
    );
    const isAfmConfirmed = originalBeneficiaryData?.afmIsConfirmed;
    const representativeMobile = useSelector(
        (state) => state.userInfoSlice.representativeMobile
    );
    const representativeFieldsFilled =
        representativeAfm || representativeFirstName || representativeLastName;

    const activeStatus = useSelector((state) => state.ticketSlice.activeStatus);
    const [expanded, setExpanded] = useState("panel1");
    const isDisabled = beneficiaryId === null && isEditMode;
    const beneficiaryTickets = useSelector((state) => state.beneficiaryFetchSlice.beneficiaryTickets);
    const dispatch = useDispatch();
    const isHistoryModalOpen = useSelector((state) => state.ticketSlice.isHistoryModalOpen);
    const shouldCreateBeneficiary = useSelector(state => state.userInfoSlice.shouldCreateBeneficiary);
    const currentBeneficiary = useSelector((state) => state.userInfoSlice.currentBeneficiary);
    // // ΠΑΡΑΜΕΤΡΟΠΟΙΗΣΗ WYQSIG EDITOR

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const isBeneficiaryDataChanged = hasBeneficiaryDataChanged(originalBeneficiaryData, currentBeneficiary);

    let changes = {};
    if (isBeneficiaryDataChanged && !shouldCreateBeneficiary) {
        changes = getBeneficiaryFieldChanges(originalBeneficiaryData, currentBeneficiary);
    } else {
        changes = getBeneficiaryFieldChanges({}, {});
    }


    const handleChipClick = () => {
        dispatch(setHistoryModal(true));
    };

    const handleModalClose = () => {
        dispatch(setHistoryModal(false));
    };

    const CustomTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: '50vw',
        },
    });

    const getBeneficiaryTooltipMessage = () => {
        if (shouldCreateBeneficiary) {
            return "Νέος Δικαιούχος";
        } else if (isBeneficiaryDataChanged) {
            return "Αλλαγή Στοιχείων Δικαιούχου";
        } else {
            return "Ίδια στοιχεία Δικαιούχου";
        }
    };

    const getChangedFieldsTooltipMessage = (changes, shouldCreateBeneficiary) => {
        // If a new beneficiary is being created, show the appropriate message
        if (shouldCreateBeneficiary) {
            return "Ο Δικαιούχος θα δημιουργηθεί";
        }

        // Filter the fields that have changed and map to friendly titles
        const changedFields = Object.keys(changes)
            .filter(key => changes[key].changed) // Only fields that have changed
            .map(field => changes[field].label) // Map to friendly field names using the label from changes
            .join(', '); // Join them with a comma separator

        // If there are changed fields, return the message with the field names
        if (changedFields) {
            return `Αλλαγές: ${changedFields}`;
        }

        // Default message if no fields have changed
        return "Ίδια στοιχεία Δικαιούχου";
    };





    const getTooltipTitle = () => {
        const tickets = beneficiaryTickets[beneficiaryId] || {};
        const carrierElements = Object.values(tickets).map((ticket, index) => {
            const carrierTitle = carriers.find(carrier => carrier.id === ticket.carrierId)?.title || "Άγνωστος Φορέας";
            return (
                <div key={index} style={styles.carrierInfo}>
                    <div style={styles.carrierTitle}>{carrierTitle}:</div>
                    <div style={styles.carrierText}>Ανοιχτά: {ticket.status4}</div>
                    <div style={styles.carrierText}>Κλειστά: {ticket.status5}</div>
                    <div style={styles.carrierText}>Reopen: {ticket.status8}</div>
                </div>
            );
        });

        return (
            <div style={styles.carriersGrid}>
                {carrierElements}
            </div>
        );
    };

    const styles = {
        carriersGrid: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: '10px',
        },
        carrierInfo: {
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '4px',
            backgroundColor: '#f9f9f9',
        },
        carrierTitle: {
            fontSize: '16px',
            fontWeight: 'bold',
            color: '#333',
        },
        carrierText: {
            fontSize: '14px',
            color: '#333',
        }
    };


    return (
        <Box>
            <Accordion
                id="panel1"
                expanded={expanded === "panel1"}
                onChange={handleAccordionChange("panel1")}
                sx={{
                    border: userFilled ? '2px solid green' : '1px solid #e0e0e0',
                    boxShadow: userFilled ? '0 0 10px rgba(0, 255, 0, 0.3)' : 'none',
                    borderRadius: '8px'
                }}

            >
                <AccordionSummary
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <Box display="flex" alignItems="center" width="100%">
                        <Box display="flex" alignItems="center" flexGrow={1}>
                            <h4>Στοιχεία Δικαιούχου</h4>
                            {userFilled && (
                                <>
                                    <Tooltip title={getChangedFieldsTooltipMessage(changes, shouldCreateBeneficiary)} arrow>
                                        <Chip
                                            label={getBeneficiaryTooltipMessage()}
                                            className="ml-2"
                                            sx={{
                                                backgroundColor: '#f5f5f5',
                                                color: '#003476',
                                                border: '1px solid #003476',
                                                fontWeight: 'bold',
                                            }}
                                        />
                                    </Tooltip>
                                </>
                            )}
                            {!isEditMode && (firstname || surname || afm || amka || phone || mobile || email || address) && (
                                <Tooltip title="Καθαρισμός Πεδίων Δικαιούχου" arrow>
                                    <DisabledByDefaultIcon
                                        style={{ cursor: 'pointer', marginLeft: '10px', color: 'red', fontSize: '30px' }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            const filteredFlags = activeFlags.filter((flag) => flag.code !== 200);
                                            dispatch(setActiveFlags(filteredFlags));
                                            dispatch(resetUserForm());
                                            dispatch(clearFetchedBeneficiaries());
                                            dispatch(clearBeneficiaryLastSearchParams());
                                            dispatch(setBeneficiaryHasOpenTickets(false));
                                            dispatch(setBeneficiaryId(null));
                                            dispatch(setFollowupTicketId(null));
                                            dispatch(setShouldCreateBeneficiary(true));
                                            dispatch(setHasBeneficiaryFetched(false));
                                            dispatch(setFollowupTicketId(null));
                                        }}
                                    />
                                </Tooltip>
                            )}

                        </Box>

                        {beneficiaryTickets[beneficiaryId] && (
                            <CustomTooltip
                                title={getTooltipTitle()}
                                placement="bottom"
                                sx={{ zIndex: 1300 }}
                                arrow
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Chip
                                    label="Ιστορικό Δικαιούχου"
                                    className={`ml-2 ${beneficiaryHasOpenTickets ? 'text-red-500 ' : 'text-green-500'}`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChipClick();
                                    }}
                                    sx={{
                                        borderRadius: 0,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    }}
                                />
                            </CustomTooltip>
                        )}


                    </Box>
                </AccordionSummary>

                <AccordionDetails>
                    <div className=" flex flex-row gap-1 ">
                        <div className="w-full">
                            <Tooltip title={isAfmConfirmed ? "Το πεδίο Όνομα είναι πιστοποιημένο απο το Taxis" : ""}
                                     arrow>
                <span> {/* Wrapper to ensure Tooltip works even if CustomTextField is disabled */}
                    <CustomTextField
                        id="name"
                        label="Όνομα"
                        variant="outlined"
                        fullWidth
                        name="name"
                        validator={validateName}
                        action={changeName}
                        value={firstname}
                        isRequired={true}
                        isChanged={changes.firstNameChanged.changed}
                        disabled={isDisabled || isAfmConfirmed || (activeStatus && activeStatus.id === 5)}
                    />
                </span>
                            </Tooltip>
                        </div>
                        <div className="w-full">
                            <Tooltip title={isAfmConfirmed ? "Το πεδίο Επώνυμο είναι πιστοποιημένο απο το Taxis" : ""}
                                     arrow>
                <span>
                    <CustomTextField
                        id="surname"
                        label="Επώνυμο"
                        variant="outlined"
                        fullWidth
                        name="surname"
                        validator={validateSurname}
                        action={changeSurname}
                        value={surname}
                        isRequired={true}
                        isChanged={changes.lastNameChanged.changed}
                        disabled={isDisabled || isAfmConfirmed || (activeStatus && activeStatus.id === 5)}
                    />
                </span>
                            </Tooltip>
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-1 ">
                        <div className="w-full">
                            <CustomTextField
                                id="amka"
                                label="AMKA"
                                variant="outlined"
                                fullWidth
                                value={amka}
                                validator={validateAMKA}
                                action={changeAmka}
                                isChanged={changes.amkaChanged.changed}
                                disabled={isDisabled || (activeStatus && activeStatus.id === 5)}
                            />
                        </div>
                        <div className="w-full">
                            <Tooltip title={isAfmConfirmed ? "Το πεδίο ΑΦΜ είναι πιστοποιημένο απο το Taxis" : ""}
                                     arrow>
                <span>
                    <CustomTextField
                        id="afm"
                        label="ΑΦΜ"
                        variant="outlined"
                        fullWidth
                        value={afm}
                        validator={validateAFM}
                        action={changeAfm}
                        isRequired={true}
                        disabled={isDisabled || isAfmConfirmed || (activeStatus && activeStatus.id === 5)}
                        isChanged={changes.afmChanged.changed}
                        className="h-12 px-3 py-6 rounded-lg text-sm"
                    />
                </span>
                            </Tooltip>
                            <FetchBeneficiary isEditMode={isEditMode}/>
                            <FetchRepresentative/>
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-1 ">
                        <div className="w-full">
                            <CustomTextField
                                id="email"
                                label="Email"
                                variant="outlined"
                                fullWidth
                                value={email}
                                validator={validateEmail}
                                action={changeEmail}
                                isChanged={changes.emailChanged.changed}
                                disabled={isDisabled || (activeStatus && activeStatus.id === 5)}
                            />
                        </div>
                        <div className="w-full">
                            <CustomTextField
                                id="phone"
                                label="Τηλέφωνο"
                                variant="outlined"
                                fullWidth
                                value={phone}
                                validator={validatePhone}
                                action={changePhone}
                                isChanged={changes.phoneChanged.changed}
                                disabled={isDisabled || (activeStatus && activeStatus.id === 5)}
                            />
                        </div>
                    </div>

                    <div className="my-4 flex flex-row gap-1 ">
                        <div className="w-full">
                            <CustomTextField
                                id="address"
                                label="Διεύθυνση"
                                variant="outlined"
                                fullWidth
                                value={address}
                                validator={validateAddress}
                                action={changeAddress}
                                isChanged={changes.addressChanged.changed}
                                disabled={isDisabled || (activeStatus && activeStatus.id === 5)}
                            />
                        </div>
                        <div className="w-full">
                            <CustomTextField
                                id="mobile"
                                label="Κινητό"
                                variant="outlined"
                                fullWidth
                                value={mobile}
                                validator={validateMobile}
                                action={changeMobile}
                                isChanged={changes.mobileChanged.changed}
                                disabled={isDisabled || (activeStatus && activeStatus.id === 5)}
                            />
                        </div>


                    </div>
                    <div className="my-4 flex flex-row gap-1 ">
                        <div className="w-full">
                            <CustomTextField
                                id="profession"
                                label="Επάγγελμα"
                                variant="outlined"
                                fullWidth
                                value={profession}
                                validator={validateProfession}
                                action={changeProfession}
                                isChanged={changes.professionChanged?.changed}
                                disabled={isDisabled || (activeStatus && activeStatus.id === 5)}
                            />
                        </div>


                    </div>
                    <div className="w-full">
                        <TextField
                            id="special-information"
                            label="Ειδικές Πληροφορίες"
                            variant="outlined"
                            fullWidth
                            value={specialInformation}
                            onChange={(e) => dispatch(changeSpecialInformation({ value: e.target.value }))}
                            error={Boolean(validateSpecialInformation(specialInformation))}
                            helperText={validateSpecialInformation(specialInformation)}
                            disabled={isDisabled || (activeStatus && activeStatus.id === 5)}
                            multiline
                            rows={4}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </div>

                </AccordionDetails>
            </Accordion>

            <Accordion
                id="panel2"
                expanded={expanded === "panel2"}
                onChange={handleAccordionChange("panel2")}
                sx={{
                    border: (representativeFilled && representativeFieldsFilled) ? '2px solid green' : '1px solid #e0e0e0',
                    boxShadow: (representativeFilled && representativeFieldsFilled) ? '0 0 10px rgba(0, 255, 0, 0.3)' : 'none',
                    borderRadius: '8px'
                }}
            >
                <AccordionSummary
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    expandIcon={<ExpandMoreIcon/>}
                >
                    <h4>Στοιχεία Αντιπροσώπου</h4>
                </AccordionSummary>
                <AccordionDetails>
                    <div className=" flex flex-row gap-1 ">
                        <div className="w-full">
                            <CustomTextField
                                id="representative-afm"
                                label="ΑΦΜ Αντιπροσώπου"
                                variant="outlined"
                                fullWidth
                                value={representativeAfm}
                                validator={validateAFM}
                                action={changeRepresentativeAfm}
                                disabled={activeStatus && activeStatus.id === 5}
                            />
                        </div>
                        <div className="w-full">
                            <CustomTextField
                                id="representative-first-name"
                                label="Όνομα Αντιπροσώπου"
                                variant="outlined"
                                fullWidth
                                value={representativeFirstName}
                                validator={validateName}
                                action={changeRepresentativeFirstName}
                                disabled={activeStatus && activeStatus.id === 5}
                            />
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-1 ">
                        <div className="w-full">
                            <CustomTextField
                                id="representative-last-name"
                                label="Επώνυμο Αντιπροσώπου"
                                variant="outlined"
                                fullWidth
                                value={representativeLastName}
                                validator={validateName}
                                action={changeRepresentativeLastName}
                                disabled={activeStatus && activeStatus.id === 5}
                            />
                        </div>
                        <div className="w-full">
                            <CustomTextField
                                id="representative-email"
                                label="Email Αντιπροσώπου"
                                variant="outlined"
                                fullWidth
                                value={representativeEmail}
                                validator={validateEmail}
                                action={changeRepresentativeEmail}
                                disabled={activeStatus && activeStatus.id === 5}
                            />
                        </div>
                    </div>
                    <div className="my-4 flex flex-row gap-1 ">
                        <div className="w-full">
                            <CustomTextField
                                id="representative-phone"
                                label="Τηλέφωνο Αντιπροσώπου"
                                variant="outlined"
                                fullWidth
                                value={representativePhone}
                                validator={validatePhone}
                                action={changeRepresentativePhone}
                                disabled={activeStatus && activeStatus.id === 5}
                            />
                        </div>
                        <div className="w-full">
                            <CustomTextField
                                id="representative-mobile"
                                label="Κινητό Αντιπροσώπου"
                                variant="outlined"
                                fullWidth
                                value={representativeMobile}
                                validator={validateMobile}
                                action={changeRepresentativeMobile}
                                disabled={activeStatus && activeStatus.id === 5}
                            />
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
            <OpenTicketsModal
                open={isHistoryModalOpen}
                onClose={handleModalClose}
                beneficiaryId={beneficiaryId}
                beneficiaryTickets={beneficiaryTickets}
                carriers={carriers}
            />

        </Box>
    );
}

export default UserInfo;
